<script>
import formVal from '@/plugins/form-validator';

export default {
  name: 'PasswordResetPage',
  data() {
    return {
      status: 'WAITING',
      form: {
        password: '',
        passwordConf: '',
      },
      errors: {
        password: null,
        passwordConf: null,
      },
    };
  },
  /* Push user to home if no token in url */
  beforeRouteEnter(to, from, next) {
    if (!('t' in to.query)) {
      next({
        name: 'LoginPage',
        replace: true,
      });
    }
    next();
  },
  methods: {
    resetPassword(evt) {
      // Prevent page from changing automatically
      evt.preventDefault();
      // Perform validation of the form contents
      const val = formVal.validate(this.form, formVal.constraints.user.passwordReset);
      if (val) {
        // An error occured in form validation
        this.errors.password = !('password' in val);
        this.errors.passwordConf = !('passwordConf' in val);
      } else {
        this.errors.password = true;
        this.errors.passwordConf = true;
        this.status = 'PENDING';
        this.$store.dispatch('user/passwordReset', {
          token: this.$route.query.t,
          password: this.form.password,
        })
          .then(() => {
            this.status = 'DONE';
          })
          .catch(() => {
            this.status = 'ERROR';
          });
      }
    },
  },
};
</script>
<template>
  <b-container>

    <!-- Header -->
    <b-row align-h='center'>
      <b-col cols='auto'>
        <h3 v-if='$route.query.r'>Set Password</h3>
        <h3 v-else>Password Reset</h3>
      </b-col>
    </b-row>

    <!--- Main Body -->
    <b-row align-h='center'>
      <!-- Reset Form -->
      <b-col v-if="status !== 'DONE'" cols='auto'>
        <b-alert variant='danger' v-bind:show="status === 'ERROR'">
          An Error Occurred. Please check you copied the link correctly.
          If the problem persists, contact support.
        </b-alert>
        <b-form @submit='resetPassword'>

          <b-form-group label='New Password'>
            <b-input name='password'
                     type='password'
                     v-model='form.password'
                     v-bind:state='errors.password' />
            <b-form-text>
              Password must contain at least one uppercase, one lowercase,
              and one numerical character
            </b-form-text>
            <b-form-invalid-feedback>Password does not meet requirements</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label='Confirm Password'>
            <b-input name='passwordConf'
                     type='password'
                     v-model='form.passwordConf'
                     v-bind:state='errors.passwordConf' />
            <b-form-invalid-feedback>Passwords do not match</b-form-invalid-feedback>
          </b-form-group>

          <b-btn type='submit' varian='primary' v-bind:disabled="status === 'ERROR'">
            <b-spinner v-if="status === 'PENDING'" small></b-spinner>
            <span v-else>Submit</span>
          </b-btn>
        </b-form>
      </b-col>

      <!-- Success Message -->
      <b-col v-else-if="status === 'DONE'" cols='auto'>
        Done! Your password has been changed. <b-link to='/'>Click here</b-link> to login.
      </b-col>

    </b-row>
    <!-- End Main Body -->
  </b-container>
</template>
